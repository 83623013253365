/****************************************************************************
 * Copyright 2021 EPAM Systems
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 ***************************************************************************/
import { Struct } from '../entities'

export class FunctionalGroupsProvider {
  private static instance: FunctionalGroupsProvider
  functionalGroupsList: Struct[]
  constructor() {
    this.functionalGroupsList = []
  }

  public static getInstance(): FunctionalGroupsProvider {
    if (!FunctionalGroupsProvider.instance) {
      FunctionalGroupsProvider.instance = new FunctionalGroupsProvider()
    }
    return FunctionalGroupsProvider.instance
  }

  public getFunctionalGroupsList() {
    return this.functionalGroupsList
  }

  public setFunctionalGroupsList(list: Struct[]): void {
    this.functionalGroupsList = list
  }
}
